
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.clientcabin-premium::v-deep {
    background-color: $white;
    color: $black;

    .option-title {
        font-size: 1.25rem;
        line-height: 1.8em;
        text-transform: uppercase;

        .option-plan {
            border: 2px solid grey;
            padding: 4px 8px;
            margin-left: 8px;
            font-weight: 600;
        }
    }

    .price {
        font-weight: 700;
        font-size: 67px;
        letter-spacing: -1px;
        text-align: center;
        padding: 25px 0;
        color: $black;
    }
}
